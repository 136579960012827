
// external

import { createGlobalStyle } from "styled-components";

// internal

import { Normalize } from './normalize';

export const Global = createGlobalStyle`
    ${Normalize}

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Lato', 'sans-serif';
        font-weight: normal;
    }
    
    h1      { font-size: calc(.5em + 1vw); }
    h2      { font-size: 1.3em; }
    h3,     { font-size: 1.2em; }
    h5, h6  { font-size: 1em; }
    
    p, article {
        margin: 0;
    }

    img, svg {
        max-height: 100%;
        max-width:  100%;
        height:     auto;
        width:      auto;
    }

    svg { 
        display: inline-block;
        vertical-align: middle;
    }
    
    body {
        font-family: 'Lato', 'sans-serif';
    }

    *, *:after, *:before {
        box-sizing: border-box;
    }

`;