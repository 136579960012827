
export const localStorage = {

    items: () => {
        if (typeof window !== 'undefined') { return window.localStorage }
        else { return {} }
    },

    getItem: (params) => {
        if (typeof window !== 'undefined') { return window.localStorage.getItem(params) } 
        else { return '{}' }
    },

    setItem: (key, value) => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, value)
        }
    },

    removeItem: (params) => {
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(params)
        }
    }

}