
// external

import React from 'react';
import Helmet from 'react-helmet';

// internal

import { AuthProvider } from 'components/auth/context'
import { Nav } from 'modules/nav'
import { Global } from 'layouts/theme/global'

import {setConfig} from 'react-hot-loader';
setConfig({logLevel: 'no-errors-please'});

export const DOMWrapper = ({  children }) => {

    return(
        <>

            <Global />
            
            <Helmet
                title='Generators'
                meta={[
                    { name: 'description', content: 'Generate to your heart\'s content' },
                    { name: 'keywords', content: 'boardgames, dungeons and dragons, random, generator, prompts' },
                ]}
            />

            <AuthProvider>
                <Nav />
                <main>{children}</main>
            </AuthProvider>
            

        </>
    );

}