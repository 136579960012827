
import React from 'react'
import { FaUser as UserIcon } from 'react-icons/fa'

import { Button } from 'components/form/button'
import { AuthConsumer } from 'components/auth/context'



class AuthIcon extends React.Component {

    componentDidMount() {
        this.props.auth.login() // attempt to login
    }

    render() {
        return <Button onClick={this.props.auth.prompt} injectStyle={`background: none; border: none; padding: 0;`}>
            {this.props.auth.user() && 
                <div>test</div>
            }
            <UserIcon />
        </Button>

    }

}

export const Auth = React.forwardRef((props, ref) => {
    return <AuthConsumer>
        {(authProps) => <AuthIcon auth={authProps} {...props} />}
    </AuthConsumer>
})