
import React from 'react'
//import { withRouter } from 'react-router'
//import qs from 'query-string'
import Auth0 from 'auth0-lock'

import { localStorage } from 'components/polyfills/local-storage'

const AuthContext = React.createContext()

class Auth extends React.Component {

    static AUTH_CONFIG = {
        clientID: 'mzyBS7lOhVBdmTVrf6u7KUYJdePEKQM3',
        domain: 'heartofcode.auth0.com',
    }

    static AUTH_OPTIONS = {
        autoParseHash: false,
        hashCleanup: false,
        params: { scope: 'openid user_metadata name picture' },
        auth: {
            redirectUrl: process.env.NODE_ENV === 'production' ? 'https://generators.heartofcode.net/dashboard' : 'http://localhost:8000/dashboard',
            sso: false,
            responseType: 'token'
        },
        socialButtonStyle: 'small',
        languageDictionary: {
            title: 'Generators',
            signupTitle: 'Generators'
        }
    }

    constructor(props) {
        super(props)

        // try-catch the Auth0 constructor because it isn't available during a build
        try {

            this.lock = new Auth0(AuthProvider.AUTH_CONFIG.clientID, AuthProvider.AUTH_CONFIG.domain, AuthProvider.AUTH_OPTIONS)
            this.hash = window.location.hash
            window.history.pushState("", document.title, window.location.pathname + window.location.search)

        } catch (err) {
            this.lock = () => {}
        }

    }

    render() {
        return <AuthContext.Provider value={{
            prompt: this.prompt,
            login: this.login,
            logout: this.logout,
            user: this.user
        }}>
            {this.props.children}
        </AuthContext.Provider>
    }

    prompt = () => {
        this.lock.show()
    }

    login = () => {

        let accessToken = localStorage.getItem('accessToken')
        let expiresAt = localStorage.getItem('expiresAt')

        let isTokenExpired = (!accessToken || !expiresAt || expiresAt - Date.now() < 10000) // 10-second buffer so the token doesn't expire in flight

        if (isTokenExpired && this.hash) {

            this.lock.resumeAuth(this.hash, (error, result) => {

                if (error) {

                    console.warn(error)

                    this.logout()
                    return

                }

                if (result) {

                    this.lock.getUserInfo(result.accessToken, (error, profile) => {

                        localStorage.setItem('accessToken', result.accessToken)
                        localStorage.setItem('expiresAt', JSON.stringify(result.expiresIn * 1000 + new Date().getTime()))

                        this.profile = this.getProfile({ accessToken: result.accessToken, error, profile })
                        delete this.hash

                    })

                } else {
                    // do nothing
                }

            })

        } else if (!isTokenExpired) {

            this.lock.getUserInfo(accessToken, (error, profile) => {
                this.getProfile({ accessToken, error, profile })
            })

        } else {

            this.logout()

        }

    }

    logout = () => {

        localStorage.removeItem('accessToken')
        localStorage.removeItem('expiresAt')
        delete this.hash
        delete this.profile

    }

    user = () => {
        return this.profile
    }

    getProfile = ({ error, accessToken, profile }) => {

        if (error) {
            return
        }

        return {
            accessToken,
            ...profile
        }

    }

}

export const AuthConsumer = AuthContext.Consumer
export const AuthProvider = Auth