
/* ========================================================

    NAV BAR

======================================================== */

// External

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * StyledNav
 */

const StyledNav = styled.nav.attrs({
    role:               'navigation',
    tabIndex:           0
})`
    position:           fixed;
    top:                0;
    left:               0;
    z-index:            100;

    background:         white;
    box-shadow:         0 -2px 10px 0 black;

    width:              100%;
    height:             ${props => props.height};
    padding:            ${props => props.inset};
    margin:             0;

    & ~ * {
        margin-top: ${props => props.height}
    }

    & .nav-mobile { display: none; }

    @media ${props => props.breakpoint || 'screen AND (max-width: 600px)'} {
        & .nav-desktop  { display: none; }
        & .nav-mobile   { display: flex; }
    }

    @media screen AND (max-width: 300px) {
        padding:        0 5px;
    }

    ${props => props.styled}
`

/**
 * StyledNavList
 */

const StyledNavList = styled.ul`
    display:            flex;
    flex-flow:          row;
    justify-content:    center;
    align-items:        center;

    height:             100%;
    padding:            0;
    margin:             0;
`

const Nav = ({ children, ...props }) => (
    <StyledNav {...props}>
        <StyledNavList>{children}</StyledNavList>
    </StyledNav>
)

const StyledNavSection = styled.li.attrs(props => ({
    role:               props.aria.role,
    "aria-label":       props.aria.label,
    "tabIndex":         0
}))`
    padding:            0;
    margin:             0;
    height:             100%;

    display:            flex;
    flex-basis:         ${props => props.flexBasis}%;
    flex-grow:          1;
    flex-flow:          row;
    justify-content:    ${props => props.align || 'center'};;
    align-items:        center;

    ${props => props.styled}
`

const StyledNavSectionList = styled.ul`
    white-space: nowrap;
    margin: 0;
    padding: 0;
    height: 100%;
`

const NavSection = ({ children, ...props }) => (
    <StyledNavSection {...props}>
        <StyledNavSectionList>{children}</StyledNavSectionList>
    </StyledNavSection>
)

const NavItem = styled.li`
    position:           relative;
    height:             100%;
    padding:            ${props => props.padding};

    display:            inline-flex;
    justify-content:    center;
    align-items:        center;
    white-space:        nowrap;

    list-style:         none;

    a:hover {
        color:          ${props => props.hover}
    }

    a {
        text-decoration:    none;
        color:              initial;
    }
`

export const NavBar = (props) => {

    let { height, inset, breakpoint } = props
    let { desktop, mobile } = props.links

    return(
        <Nav height={height} inset={inset} breakpoint={breakpoint}>

                {desktop && desktop.map((section, index) => {

                    let { align, aria, styled, render, linkPadding = props.linkPadding || 0 } = section

                    return <NavSection 
                        className="nav-desktop" key={index} 
                        align={align} 
                        aria={aria}
                        flexBasis={(1/desktop.length) * 100}
                        styled={styled}
                    >
                        {render.map 
                            ? render.map((node, index) => <NavItem key={index} padding={linkPadding}>{node}</NavItem>)
                            : <NavItem padding={linkPadding}>{render}</NavItem>
                        }
                    </NavSection>
                })}

                {mobile && mobile.map((section, index) =>{

                    let { align, aria, styled, render, linkPadding = props.linkPadding || 0 } = section

                    return <NavSection 
                        className="nav-mobile" key={index} 
                        align={align} 
                        aria={aria}
                        flexBasis={(1/desktop.length) * 100}
                        styled={styled}
                    >
                        {render.map 
                            ? render.map((node, index) => <NavItem key={index} padding={linkPadding}>{node}</NavItem>)
                            : <NavItem padding={linkPadding}>{render}</NavItem>
                        }
                    </NavSection>
                })}
        </Nav>
    )

}

const ariaPropType = PropTypes.shape({
    label:          PropTypes.string,
    role:           PropTypes.string,
})

const linkPropType = PropTypes.shape({
    aria:           ariaPropType,
    align:          PropTypes.string,
    render:         PropTypes.node,
    styled:         PropTypes.string, // backdoor for injecting CSS
})

NavBar.propTypes = {
    height:         PropTypes.string,
    padding:        PropTypes.string,
    styled:         PropTypes.string, // backdoor for injecting CSS
    links:          PropTypes.shape({
        desktop:    PropTypes.arrayOf(linkPropType),
        mobile:     PropTypes.arrayOf(linkPropType),
        breakpoint: PropTypes.string,
    }),
}