
import React from 'react'
import Link from 'gatsby-link'

import { Auth } from 'components/auth'
import { DiceText } from 'components/dice-text'
import { NavBar } from 'components/nav'

export const Nav = () => {

    return <NavBar
        height={'100px'}
        inset='0 20px'
        linkPadding='0 1vw'
        breakpoint='(max-width: 700px)'
        links={{
            desktop: [
                {
                    align: 'flex-start',
                    aria: { label: 'Logo' },
                    render: [
                        <Link key='home' to='/'>
                            <DiceText text={'RNG'} size='1' />
                        </Link>,
                    ]
                },
                {
                    align: 'flex-end',
                    aria: { label: 'Primary' },
                    render: [
                        <Link key='browse' to='/browse'>Browse</Link>,
                        <Link key='build' to='/builder'>Build</Link>,
                        <Auth key='login' />
                    ]
                }
            ],
            mobile: [
                {
                    align: 'flex-start',
                    aria: { label: 'Logo' },
                    render: [
                        <Link key='home' to='/'>RandomGen</Link>,
                    ]
                },
                {
                    align: 'flex-end',
                    aria: { label: 'Primary' },
                    render: [
                    ]
                }
            ]
        }}
    />

}