// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-generator-js": () => import("/opt/build/repo/src/layouts/generator.js" /* webpackChunkName: "component---src-layouts-generator-js" */),
  "component---src-pages-browse-js": () => import("/opt/build/repo/src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-builder-js": () => import("/opt/build/repo/src/pages/builder.js" /* webpackChunkName: "component---src-pages-builder-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-generator-js": () => import("/opt/build/repo/src/pages/generator.js" /* webpackChunkName: "component---src-pages-generator-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

