
import styled from 'styled-components'

export const Button = styled.button.attrs(props => ({
    type: props.type || 'submit'
}))`
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    margin-left: 0;
    background: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:active, &:focus {
        background: #ffacc2;
    }

    ${props => props.injectStyle}
`