
import React from 'react'
import styled from 'styled-components'

const Die = styled.span`
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 0.25em 0;
    width: 1.5em;
    text-align: center;
    background: #ff6666;
    color: white;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: ${props => props.size ? (props.size + 'em') : '2.5em'};
`

export const DiceText = (props) => {
    return Array.from(props.text).map((letter, index) => {
        return <Die key={letter + index} size={props.size}>{letter}</Die>
    })
}