
import React from 'react'
import styled from 'styled-components'
import { FaBars as BarsIcon } from 'react-icons/fa'

const Button = styled.button.attrs({
    'aria-haspopup': true
})`
    background: none;
    border:     none;
    margin:     0;
    padding:    0;
`

const NavList = styled.ul.attrs({
    'aria-expanded': props => props.open || false
})`
`

export class NavDrawer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <>
            <Button aria-haspopup={true}><BarsIcon size={32} /></Button>
            <NavList>

            </NavList>
        </>
    }

}